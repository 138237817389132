<template>
  <div>
    <div class="fixed-buttons">
      <button
        @click="navigateToMiniProgram"
        class="refresh-button iconfont icon-fanhuishouye"
      ></button>
      <!-- <button
        @click="toggleCurrency"
        class="refresh-button iconfont icon-huishuai"
      ></button> -->
      <button
        @click="refreshData"
        class="refresh-button iconfont icon-icon"
      ></button>
      <div class="translate-buttons">
        <a
          href="javascript:translate.changeLanguage('chinese_simplified');"
          class="ignore"
          >中文</a
        >
        &nbsp;|&nbsp;
        <a
          href="javascript:translate.changeLanguage('japanese');"
          class="ignore"
          >日本語</a
        >
      </div>
    </div>
    <div v-if="data">
      <!-- 手动图片轮播 -->
      <div class="carousel">
        <div
          class="carousel-container"
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
          <div
            v-for="(photo, index) in data.photos"
            :key="index"
            class="carousel-slide"
          >
            <img :src="photo" :alt="data.name" class="carousel-image" />
          </div>
        </div>
        <button
          v-if="data.photos.length > 1"
          @click="prevSlide"
          class="carousel-control prev"
        >
          ‹
        </button>
        <button
          v-if="data.photos.length > 1"
          @click="nextSlide"
          class="carousel-control next"
        >
          ›
        </button>
      </div>
      <div class="title-container">
        <div class="translate title">{{ data.name }}</div>
        <div class="copy-button" @click="copy(data.name)">复制</div>
      </div>
      <div class="item-info">
        <span class="item-price">{{ convertPrice(data.price) }}</span>
        <span
          v-if="data.status"
          class="status"
          :class="{
            on_sale: data.status === 'on_sale',
            trading: data.status === 'trading',
          }"
        >
          {{ data.status === "on_sale" ? "在售" : "已售出" }}
        </span>
        <span v-if="data.shipping_payer.id == 2" class="shipping">包邮</span>
      </div>
      <div class="seller-info" @click="goToProfile(data.seller.id)">
        <img
          :src="useCdn(data.seller.photo_thumbnail_url)"
          alt="Seller Photo"
          class="seller-photo"
        />
        <div class="seller-name">{{ data.seller.name }}</div>
        <div class="seller-rating">
          <i class="fas fa-star"></i> {{ data.seller.star_rating_score }}
        </div>
      </div>
      <div class="section-title">
        <span class="line"></span>
        <span class="title">商品信息</span>
        <span class="line"></span>
      </div>
      <ul class="info-list">
        <li>
          <span class="list-title">商品编号</span>
          <div class="list-value">
            <span>{{ data.id }}</span>
            <span class="copy-button" @click="copy(data.id)">复制</span>
          </div>
        </li>
        <li>
          <span class="list-title">商品分类</span>
          <span class="list-value">{{
            data.item_category.root_category_name
          }}</span>
        </li>
        <li>
          <span class="list-title">使用情况</span>
          <span class="list-value">{{ data.item_condition.name }}</span>
        </li>
        <li>
          <span class="list-title">日本运费</span>
          <span class="list-value">{{ data.shipping_payer.name }}</span>
        </li>
        <li>
          <span class="list-title">日本邮寄方式</span>
          <span class="list-value">{{ data.shipping_method.name }}</span>
        </li>
        <li>
          <span class="list-title">发货地</span>
          <span class="list-value">{{ data.shipping_from_area.name }}</span>
        </li>
        <li>
          <span class="list-title">发货天数</span>
          <span class="list-value">{{ data.shipping_duration.name }}</span>
        </li>
      </ul>
      <div class="section-title">
        <span class="line"></span>
        <span class="title">商品描述</span>
        <span class="line"></span>
      </div>
      <p class="translate" style="margin-bottom: 80px">
        {{ data.description }}
      </p>
    </div>
    <div v-else>
      <div class="skeleton-screen">
        <div class="skeleton-image"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-price"></div>
      </div>
    </div>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <!-- 悬浮底边栏 -->
    <div class="bottom-bar">
      <button
        class="bottom-button iconfont icon-fenxiang"
        @click="copyLink(data)"
      ></button>
      <button
        class="bottom-button iconfont"
        :class="
          data && data.is_marked
            ? 'icon-shoucangwancheng1 marked'
            : 'icon-shoucang'
        "
        @click="mark(data)"
      ></button>
      <button class="bottom-button cart-button" style="display: none">
        加入购物车
      </button>
      <button class="bottom-button buy-button" style="display: none">
        直接购买
      </button>
      <button class="bottom-button buy-button" disabled="true">
        复制商品编号联系客服购买
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api/axios";
import axios from "axios";
/* global wx */
export default {
  props: ["id", "site", "token"],
  data() {
    return {
      data: null,
      error: null,
      currentIndex: 0,
      isYen: true,
      conversionRate: 0.065, // 假设有人民币的转换率
    };
  },
  created() {
    window.translate.language.setLocal("japanese");
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
    this.fetchData();
    this.fetchConversionRate(); // 如果需要，获取汇率
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.title = "商品详情";
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    this.translateContent();
  },
  methods: {
    refreshData() {
      this.fetchData();
    },
    async fetchData() {
      try {
        const response = await api.get(
          `/wxservice/${this.site}/item/${this.id}`
        );

        if (response.data) {
          console.log(response.data);
          this.data = response.data;
        }
      } catch (error) {
        this.error = "Error fetching data";
      }
    },
    translateContent() {
      this.$nextTick(() => {
        const tranList = document.getElementsByClassName("translate");
        if (tranList.length > 0) {
          window.translate.selectLanguageTag.show = false;
          window.translate.execute(tranList);
        }
      });
    },
    navigateToMiniProgram() {
      if (typeof wx !== "undefined" && wx.miniProgram) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.reLaunch({
              url: "/pages/tabBar/news/news",
            });
          } else {
            console.error("Not in mini program environment");
          }
        });
      } else {
        console.error("wx or wx.miniProgram is not available");
      }
    },
    toggleCurrency() {
      this.isYen = !this.isYen;
    },
    fetchConversionRate() {
      axios
        .get("https://api.exchangerate-api.com/v4/latest/JPY")
        .then((response) => {
          this.conversionRate = response.data.rates.CNY;
        })
        .catch((error) => {
          console.error("获取汇率失败:", error);
        });
    },
    convertPrice(price) {
      return `${price} JPY ≈ ${(price * this.conversionRate).toFixed(2)} RMB`;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.data.photos.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.data.photos.length) %
        this.data.photos.length;
    },
    copyLink(data) {
      const productLink = `https://jp.mercari.com/item/${data.id}`;
      navigator.clipboard
        .writeText(productLink)
        .then(() => {
          alert("商品链接已复制到剪贴板");
        })
        .catch((err) => {
          console.error("复制失败:", err);
        });
    },
    copy(data) {
      navigator.clipboard.writeText(data);
    },
    goToProfile(sellerId) {
      this.$router.push({
        path: `/profile/view/${this.site}/${sellerId}/卖家详情`,
        query: { token: this.token },
      });
    },
    async mark(data) {
      if (!data) return;
      try {
        const payload = {
          site: this.site,
          id: this.id,
          isMarked: !data.is_marked,
        };
        await api.post(`/wxservice/monitor/mark`, payload);
        data.is_marked = !data.is_marked;
      } catch (error) {
        this.error = "更新数据失败";
      }
    },
    useCdn(url) {
      if (url.includes("https://static.mercdn.net/item/detail/orig/")) {
        return url.replace(
          "https://static.mercdn.net/item/detail/orig/",
          "https://image.liuliuyi.site/c!/w=240,f=webp/thumb/"
        );
      } else if (url.includes("https://static.mercdn.net/")) {
        return url.replace(
          "https://static.mercdn.net/",
          "https://image.liuliuyi.site/"
        );
      } else {
        return url;
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.bootcdn.net/ajax/libs/font-awesome/5.15.4/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap");

.refresh-button {
  background-color: transparent;
  color: #111;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transition: color 0.3s;
}

.refresh-button:hover {
  color: #0056b3;
}

.fixed-buttons {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.translate-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.translate-buttons a {
  text-decoration: none;
  color: #111;
  transition: color 0.3s;
  font-family: "Roboto", sans-serif;
}

.translate-buttons a:hover {
  color: #0056b3;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 70px; /* 增加顶部间距 */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  min-width: 100%;
}

.carousel-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 30px;
  cursor: pointer;
  padding: 10px 20px; /* 修改为椭圆形 */
  z-index: 100;
  border-radius: 50px; /* 修改为椭圆形 */
}

.carousel-control.prev {
  left: 10px;
}

.carousel-control.next {
  right: 10px;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #333;
}

.copy-button {
  cursor: pointer;
  padding: 2px 5px;
  font-size: 10px;
  color: #d14;
  border: 1px solid #d14;
  border-radius: 10px;
  margin-left: 15px;
}

.item-info {
  margin-top: 10px;
}

p {
  font-size: 16px;
  text-align: center;
  color: #555;
  font-family: "Roboto", sans-serif;
}

.item-price {
  color: #d14;
  padding: 5px;
  border-radius: 5px;
}

.status {
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  background-color: #d14;
  color: #fff;
  border: 1px solid #d14;
}

.status.on_sale {
  color: #000;
  background-color: transparent;
  border-color: #000;
}

.status.trading {
  color: #d14;
}

.shipping {
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  border: 1px solid #000;
  color: #000;
}

.seller-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
}

.seller-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.seller-name {
  font-size: 16px;
  font-weight: bold;
  flex-grow: 1;
}

.seller-rating {
  margin-left: 10px;
  font-size: 16px;
  color: #ff9800;
  white-space: nowrap;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.section-title .line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.section-title .title {
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.info-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
}

.info-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-title {
  width: 30%;
  font-weight: bold;
  padding-right: 10px;
  padding-left: 10px;
}

.list-value {
  width: 70%;
}

.skeleton-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.skeleton-image {
  width: 80%;
  height: 200px;
  background: #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.skeleton-text {
  width: 60%;
  height: 20px;
  background: #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.skeleton-price {
  width: 40%;
  height: 20px;
  background: #ddd;
  border-radius: 5px;
}

.bottom-bar {
  position: fixed;
  bottom: -2px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.bottom-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #0056b3;
  font-family: "Roboto", sans-serif;
}

.bottom-button.iconfont {
  font-size: 22px;
}

.cart-button {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.buy-button {
  background-color: #d14;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .carousel-control {
    font-size: 24px;
    padding: 8px 16px; /* 修改为椭圆形 */
  }
}

.marked {
  color: #ff4500;
}
</style>