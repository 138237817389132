/* global wx */
<template>
  <div @scroll="handleScroll">
    <div class="fixed-buttons">
      <button
        @click="refreshData"
        class="refresh-button iconfont icon-icon"
      ></button>
      <!-- <button
        @click="navigateToMiniProgram"
        class="refresh-button iconfont icon-houtui"
      ></button> -->
      <button
        @click="toggleFilter"
        class="refresh-button iconfont icon-guolvpaixu"
      ></button>
      <div class="translate-buttons">
        <a
          href="javascript:translate.changeLanguage('chinese_simplified');"
          class="ignore"
          >中文</a
        >
        &nbsp;|&nbsp;
        <a
          href="javascript:translate.changeLanguage('japanese');"
          class="ignore"
          >日本語</a
        >
      </div>
    </div>

    <div v-if="isFilterActive" class="sidebar-filter">
      <h3>过滤</h3>
      <div class="filter-options">
        <button
          @click="setFilter('all')"
          :class="{ active: state.selectedStatusFilter === 'all' }"
        >
          全部文章
        </button>
        <button
          @click="setFilter('monitor')"
          :class="{ active: state.selectedStatusFilter === 'monitor' }"
        >
          我的监控
        </button>
        <button
          @click="setFilter('article')"
          :class="{ active: state.selectedStatusFilter === 'article' }"
        >
          我的订阅
        </button>
      </div>
      <h3>订阅时间排序</h3>
      <div class="filter-options">
        <button
          @click="setSort('newest')"
          :class="{ active: selectedSorter === 'newest' }"
        >
          从新到旧
        </button>
        <button
          @click="setSort('oldest')"
          :class="{ active: selectedSorter === 'oldest' }"
        >
          从旧到新
        </button>
      </div>
    </div>

    <div v-if="isFilterActive" class="overlay" @click="toggleFilter"></div>

    <div v-if="state.loading" class="skeleton-list">
      <div v-for="i in 5" :key="i" class="skeleton-item">
        <div class="skeleton-image"></div>
        <div class="skeleton-content">
          <div class="skeleton-title"></div>
          <div class="skeleton-description"></div>
        </div>
      </div>
    </div>
    <div v-else-if="state.data.length > 0" class="article-list">
      <div
        v-for="(item, index) in state.data"
        :key="index"
        class="article-item"
      >
        <div v-if="!isItemLoaded(item)" class="skeleton-item">
          <div class="skeleton-image"></div>
          <div class="skeleton-content">
            <div class="skeleton-title"></div>
            <div class="skeleton-description"></div>
          </div>
        </div>
        <template v-else>
          <div class="article-image">
            <img :src="item.photo" alt="Item image" />
          </div>
          <div class="article-content" @click="goToItemDetail(item)">
            <div class="article-title">
              <a
                class="translate"
                @click.stop="handleLinkClick($event, item)"
                >{{ item.name }}</a
              >
            </div>
            <div
              v-if="item.type === 1"
              class="article-description translate"
              v-html="stripImages(item.description)"
            ></div>
            <div v-else class="article-price">{{ item.price }}</div>
          </div>
          <div class="article-footer">
            <span class="article-date">{{ formatDate(item.date) }}</span>
            <div class="action-buttons">
              <button
                @click.stop.prevent="mark(item)"
                :class="[
                  'refresh-button',
                  'iconfont',
                  item.is_marked ? 'icon-shoucangwancheng1' : 'icon-shoucang',
                  { marked: item.is_marked },
                ]"
              ></button>
              <button
                @click="copyLink(item)"
                class="refresh-button iconfont icon-fenxiang1"
              ></button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="section-title" v-if="allDataLoaded">
      <span class="line"></span>
      <span class="title">到底啦</span>
      <span class="line"></span>
    </div>
    <div v-if="error" class="article-list">
      <p>{{ error }}</p>
    </div>
    <button
      v-if="showScrollTopButton"
      @click="scrollToTop"
      class="scroll-to-top"
    >
      ↑
    </button>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import api from "@/api/axios";

export default {
  props: ["token"],
  data() {
    return {
      data: [],
      error: null,
      page: 1,
      limit: 30,
      allDataLoaded: false,
      showScrollTopButton: false,
      loading: true,
      isFilterActive: false,
      selectedStatusFilter: "all",
      type: "all",
      selectedSorter: "newest",
    };
  },
  setup() {
    const state = reactive({
      data: [],
      error: null,
      page: 1,
      limit: 30,
      allDataLoaded: false,
      showScrollTopButton: false,
      loading: true,
      isFilterActive: false,
      selectedStatusFilter: "all",
      selectedSorter: "newest",
      isWechatMiniProgram: false,
    });

    const isItemLoaded = computed(() => {
      return (item) => item.loaded && item.name != null && item.photo != null;
    });

    return {
      state,
      isItemLoaded,
    };
  },
  created() {
    window.translate.language.setLocal("japanese");
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
    this.debouncedFetchData = this.debounce(this.fetchData, 500);
    this.debouncedFetchData();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.title = "我的收藏";
    this.isWechatMiniProgram =
      navigator.userAgent && navigator.userAgent.indexOf("miniProgram") > -1;
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    this.translateContent();
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    async fetchData() {
      if (this.state.allDataLoaded) return;

      this.state.loading = true;
      try {
        const payload = {
          page: this.state.page,
          limit: this.state.limit,
          type: this.state.selectedStatusFilter,
          sort: this.selectedSorter === "newest" ? -1 : 1,
        };
        const response = await api.post(`/wxservice/marked/view`, payload);

        if (response.data.length < this.state.limit) {
          this.state.allDataLoaded = true;
        }

        const newData = response.data.map((item) => ({
          ...item,
          loaded: false,
        }));

        // 等待所有项目加载完成
        await Promise.all(newData.map(this.loadItemDetails));

        this.state.data = [...this.state.data, ...newData];
        this.state.page += 1;
      } catch (error) {
        this.state.error = "获取数据失败";
      } finally {
        this.state.loading = false;
      }
    },
    async loadItemDetails(item) {
      try {
        let response;
        if (item.type === 0) {
          response = await api.get(`/wxservice/${item.site}/item/${item.id}`);
          item.name = response.data.name;
          item.price = response.data.price + "円";
          item.photo = response.data.photos[0];
          if (item.site === "mercari") {
            item.link = `https://jp.mercari.com/item/${item.id}`;
          }
        } else if (item.type === 1) {
          response = await api.get(`/wxservice/article/${item.id}`);
          item.name = response.data.name || "";
          item.description = response.data.description || "";
          item.link = response.data.link || "";
          if (response.data.photos.length > 0) {
            item.photo = response.data.photos[0];
          } else {
            item.photo = this.getFirstImage(item.description);
          }
        }
        item.loaded = true;
      } catch (error) {
        console.error("加载项目详情失败:", error);
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      this.showScrollTopButton = window.scrollY > 200;

      if (scrollPosition >= documentHeight - 10) {
        this.debouncedFetchData();
      }
    },
    translateContent() {
      this.$nextTick(() => {
        const tranList = document.getElementsByClassName("translate");
        if (tranList.length > 0) {
          window.translate.execute(tranList);
        }
      });
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    refreshData() {
      this.state.page = 1;
      this.state.allDataLoaded = false;
      this.state.data = [];
      this.debouncedFetchData();
    },
    async mark(item) {
      try {
        let payload;
        if (item.type == 0) {
          payload = {
            site: item.site,
            id: item.id,
            isMarked: !item.is_marked,
          };
        } else if (item.type == 1) {
          payload = {
            feedItemId: item.feedItemId,
            articleIds: [item.id],
            isMarked: !item.is_marked,
          };
        }
        await api.post(`/wxservice/article/updateStatus`, payload);
        item.is_marked = !item.is_marked;
      } catch (error) {
        this.error = "更新数据失败";
      }
    },
    getFirstImage(description) {
      const match = description.match(/<img.*?src="(.*?)"/);
      return match ? match[1] : "https://via.placeholder.com/100"; // 默认图片URL
    },
    stripImages(description) {
      return description.replace(/<[^>]*>/g, "").trim();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString("zh-CN", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    toggleFilter() {
      this.isFilterActive = !this.isFilterActive;
    },
    setFilter(filter) {
      this.state.selectedStatusFilter = filter;
      this.refreshData();
      this.toggleFilter();
    },
    setSort(sort) {
      this.selectedSorter = sort;
      this.refreshData();
      this.toggleFilter();
    },
    goToArticleDetail(item) {
      this.$router.push({
        name: "ArticleDetail",
        params: {
          feedItemId: this.feedItemId,
          id: item.id,
        },
      });
    },
    copyLink(item) {
      navigator.clipboard
        .writeText(item.link)
        .then(() => {
          alert("链接已复制到剪贴板");
        })
        .catch((err) => {
          console.error("复制失败:", err);
        });
    },
    goToItemDetail(item) {
      if (item.type === 0) {
        this.$router.push({
          name: "DetailView",
          params: {
            id: item.id,
            site: item.site,
          },
        });
      } else {
        this.$router.push({
          name: "ArticleDetail",
          params: {
            id: item.id,
            feedItemId: item.feedItemId,
          },
        });
      }
    },
    handleLinkClick(event, item) {
      event.preventDefault();
      if (!this.isWechatMiniProgram) {
        window.open(item.link, "_blank");
      } else {
        this.goToArticleDetail(item);
      }
    },
  },
};
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: background-color 0.3s;
}

.refresh-button {
  background-color: transparent;
  color: #111;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transition: color 0.3s;
}

.fixed-buttons {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  gap: 10px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.translate-buttons {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.translate-buttons a {
  text-decoration: none;
  color: #111;
  transition: color 0.3s;
}

.translate-buttons a:hover {
  color: #0056b3;
}

.article-list,
.skeleton-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;
}

.article-item,
.skeleton-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
}

.article-image,
.skeleton-image {
  width: 100px;
  height: 100px;
  margin-right: 15px;
  flex-shrink: 0;
}

.article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.article-content,
.skeleton-content {
  flex: 1;
  min-width: 0; /* 防止flex item溢出 */
  cursor: pointer;
}

.article-title,
.skeleton-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.article-title a {
  color: #333;
  text-decoration: none;
}

.article-description,
.skeleton-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 限制显示行数 */
  -webkit-box-orient: vertical;
}

.article-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
}

.article-date {
  font-size: 12px;
  color: #999;
}

.action-buttons button {
  font-size: 18px;
}

.skeleton-image,
.skeleton-title,
.skeleton-description {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.skeleton-title {
  height: 24px;
  width: 70%;
}

.skeleton-description {
  height: 14px;
  margin-top: 10px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.sidebar-filter {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    right: -300px;
  }
  to {
    right: 0;
  }
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-options button {
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s;
}

.filter-options button.active {
  background-color: #007bff;
  color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.marked {
  color: #ff4500;
}
</style>