<template>
  <div>
    <div class="fixed-buttons">
      <button
        @click="navigateToMiniProgram"
        class="refresh-button iconfont icon-fanhuishouye"
      ></button>
      <button
        @click="toggleCurrency"
        class="refresh-button iconfont icon-huishuai"
      ></button>
      <button
        @click="goToDetail"
        class="refresh-button iconfont icon-xiangqing"
      ></button>
    </div>

    <div class="lowest-price">
      最低价格：<b>{{ lowestPrice }}</b>
    </div>

    <div v-if="error">{{ error }}</div>

    <div v-if="data">
      <div class="timeline">
        <div
          class="timeline-item"
          v-for="(priceUpdate, index) in sortedPriceUpdates"
          :key="index"
          :class="{
            'price-up': isPriceUp(priceUpdate, index),
            'price-down': isPriceDown(priceUpdate, index),
            today: isToday(priceUpdate.updated),
          }"
        >
          <div class="timeline-content">
            <h3>{{ formatTimestamp(priceUpdate.updated) }}</h3>
            <p>{{ convertPrice(priceUpdate.price) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/axios";
import axios from "axios";
/* global wx */
export default {
  props: ["queueName", "jobId", "title", "token"],
  data() {
    return {
      lowestPrice: null,
      data: null,
      error: null,
      isYen: true,
      conversionRate: 0.065, // 假设有人民币的转换率
    };
  },
  created() {
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
    this.fetchData();
    this.fetchConversionRate(); // 如果需要，获取汇率
  },
  mounted() {
    document.title = this.title || "商品价格"; // 使用传入的标题
  },
  methods: {
    async fetchData() {
      try {
        const payload = {
          queueName: this.queueName,
          jobId: this.jobId,
        };
        const response = await api.post(`/wxservice/monitor/view`, payload);

        if (response.data) {
          this.data = response.data;
          this.lowestPrice = this.calculateLowestPrice();
        }
      } catch (error) {
        this.error = "Error fetching data";
      }
    },
    goToDetail() {
      this.$router.push({
        name: "DetailView",
        params: { id: this.data[0].id, site: "mercari" },
      });
    },
    toggleCurrency() {
      this.isYen = !this.isYen;
    },
    async fetchConversionRate() {
      try {
        const response = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/JPY"
        );
        this.conversionRate = response.data.rates.CNY;
      } catch (error) {
        console.error("获取汇率失败:", error);
      }
    },
    convertPrice(price) {
      return this.isYen
        ? `${price} JPY`
        : `${(price * this.conversionRate).toFixed(2)} RMB`;
    },
    formatTimestamp(timestamp) {
      if (timestamp < 10000000000) {
        timestamp *= 1000; // 10位数为秒，13位数为毫秒
      }
      const date = new Date(timestamp); // 转换为毫秒
      return date.toLocaleString(); // 格式化日期和时间
    },
    getSortedPriceUpdates() {
      return this.data.sort((a, b) => b.updated - a.updated);
    },
    isPriceUp(priceUpdate, index) {
      if (index === this.data.length - 1) return false; // 最后一个点不比较
      return this.data[index].price > this.data[index + 1].price;
    },
    isPriceDown(priceUpdate, index) {
      if (index === this.data.length - 1) return false; // 最后一个点不比较
      return this.data[index].price < this.data[index + 1].price;
    },
    isToday(timestamp) {
      const today = new Date();
      const date = new Date(timestamp);
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    calculateLowestPrice() {
      return Math.min(...this.data.map((update) => update.price));
    },
    navigateToMiniProgram() {
      if (typeof wx !== "undefined" && wx.miniProgram) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.reLaunch({
              url: "/pages/tabBar/news/news",
            });
          } else {
            console.error("Not in mini program environment");
          }
        });
      } else {
        console.error("wx or wx.miniProgram is not available");
      }
    },
  },
  computed: {
    sortedPriceUpdates() {
      return this.data ? this.getSortedPriceUpdates() : [];
    },
  },
};
</script>

<style scoped>
.lowest-price {
  margin-top: 60px;
  background: #f8f8f8;
  padding: 15px 15px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.refresh-button {
  background-color: transparent;
  color: #111;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transition: color 0.3s;
}

.refresh-button:hover {
  color: #0056b3;
}

.fixed-buttons {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.timeline {
  position: relative;
  max-width: 600px;
  padding: 0 20px;
  list-style-type: none;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  width: 2px;
  height: 100%;
  background-color: #ddd;
}

.timeline-item {
  position: relative;
  margin: 20px 0;
  padding-left: 40px;
}

.timeline-item::before {
  content: "";
  position: absolute;
  top: 20px;
  left: -9px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ddd;
}

.timeline-item.today::before {
  border-color: #007bff;
}

.timeline-item.price-up {
  color: #d14;
}

.timeline-item.price-up::before {
  background-color: #d14;
}

.timeline-item.price-down {
  color: green;
}

.timeline-item.price-down::before {
  background-color: green;
}

.timeline-content {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.timeline-content h3 {
  margin: 0;
  font-size: 18px;
}

.timeline-content p {
  margin: 5px 0 0;
  font-size: 16px;
}
</style>
