<template>
  <div @scroll="handleScroll">
    <div class="fixed-buttons">
      <button
        @click="refreshData"
        class="refresh-button iconfont icon-icon"
      ></button>
      <!-- <button
        @click="navigateToMiniProgram"
        class="refresh-button iconfont icon-houtui"
      ></button> -->
      <button
        @click="toggleCurrency"
        class="refresh-button iconfont icon-huishuai"
      ></button>
      <div class="translate-buttons">
        <a
          href="javascript:translate.changeLanguage('chinese_simplified');"
          class="ignore"
          >中文</a
        >
        &nbsp;|&nbsp;
        <a
          href="javascript:translate.changeLanguage('japanese');"
          class="ignore"
          >日本語</a
        >
      </div>
    </div>
    <div v-if="loading" class="skeleton-container">
      <div v-for="n in 10" :key="n" class="skeleton-item">
        <div class="skeleton-image"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-price"></div>
      </div>
    </div>
    <div v-else class="waterfall-container">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="waterfall-item"
        @click="goToDetail(item)"
      >
        <div class="image-container">
          <div v-if="!item.imageLoaded" class="skeleton-image"></div>
          <img
            :src="useCdn(item.thumbnails[0])"
            class="data-img"
            :alt="item.name"
            @load="imageLoaded(item)"
            :style="{ display: item.imageLoaded ? 'block' : 'none' }"
          />
        </div>
        <div class="translate text-truncate">{{ item.name }}</div>
        <div class="item-price ignore">{{ convertPrice(item.price) }}</div>
      </div>
    </div>
    <div class="section-title" v-if="allDataLoaded">
      <span class="line"></span>
      <span class="title">到底啦</span>
      <span class="line"></span>
    </div>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <button
      v-if="showScrollTopButton"
      @click="scrollToTop"
      class="scroll-to-top"
    >
      ↑
    </button>
  </div>
</template>

<script>
import api from "@/api/axios";
import axios from "axios";
/* global wx */
export default {
  props: ["queueName", "jobId", "title", "token"],
  data() {
    return {
      data: [],
      error: null,
      start: 0,
      stop: 30,
      allDataLoaded: false,
      showScrollTopButton: false,
      loading: true,
      isYen: true,
      conversionRate: 0.065,
    };
  },
  created() {
    window.translate.language.setLocal("japanese");
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
    this.throttledHandleScroll = this.throttle(this.handleScroll);
    this.fetchData();
    this.fetchConversionRate();
  },
  mounted() {
    window.addEventListener("scroll", this.throttledHandleScroll);
    if (this.title) {
      document.title = this.title;
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
  },
  methods: {
    throttle(func) {
      let ticking = false;
      return function () {
        if (!ticking) {
          window.requestAnimationFrame(() => {
            func.apply(this, arguments);
            ticking = false;
          });
          ticking = true;
        }
      };
    },
    async fetchData() {
      if (this.allDataLoaded) return;

      try {
        const payload = {
          queueName: this.queueName,
          jobId: this.jobId,
          start: this.start,
          stop: this.stop,
        };
        const response = await api.post(`/wxservice/monitor/view`, payload);

        if (response.data.length < this.stop - this.start) {
          this.allDataLoaded = true;
        }

        this.data = [
          ...this.data,
          ...response.data.map((item) => ({ ...item, imageLoaded: false })),
        ];
        this.start += 30;
        this.stop += 30;
      } catch (error) {
        this.error = "获取数据失败";
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      this.showScrollTopButton = window.scrollY > 200;

      if (this.loading || this.allDataLoaded) return;

      if (scrollPosition >= documentHeight - 10) {
        this.fetchData();
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    refreshData() {
      this.start = 0;
      this.stop = 30;
      this.allDataLoaded = false;
      this.data = [];
      this.loading = true;
      this.fetchData();
    },
    navigateToMiniProgram() {
      if (typeof wx !== "undefined" && wx.miniProgram) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.reLaunch({
              url: "/pages/tabBar/news/news",
            });
          } else {
            console.error("Not in mini program environment");
          }
        });
      } else {
        console.error("wx or wx.miniProgram is not available");
      }
    },
    goToDetail(item) {
      this.$router.push({
        name: "DetailView",
        params: { id: item.id, site: "mercari" },
      });
    },
    toggleCurrency() {
      this.isYen = !this.isYen; // 切换货币类型
    },
    fetchConversionRate() {
      axios
        .get("https://api.exchangerate-api.com/v4/latest/JPY")
        .then((response) => {
          this.conversionRate = response.data.rates.CNY;
        })
        .catch((error) => {
          console.error("获取汇率失败:", error);
        });
    },
    convertPrice(price) {
      return this.isYen
        ? `${price} JPY`
        : `${(price * this.conversionRate).toFixed(2)} RMB`;
    },
    useCdn(url) {
      if (url.includes("https://static.mercdn.net/item/detail/orig/")) {
        return url.replace(
          "https://static.mercdn.net/item/detail/orig/",
          "https://image.liuliuyi.site/c!/w=240,f=webp/thumb/"
        );
      } else if (url.includes("https://static.mercdn.net/")) {
        return url.replace(
          "https://static.mercdn.net/",
          "https://image.liuliuyi.site/"
        );
      } else {
        return url;
      }
    },
    imageLoaded(item) {
      item.imageLoaded = true;
    },
  },
};
</script>

<style scoped>
.custom-font {
  font-family: "iconfont", sans-serif;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  transition: background-color 0.3s;
}

.refresh-button {
  background-color: transparent;
  color: #111;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transition: color 0.3s;
}

.fixed-buttons {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  gap: 10px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.translate-buttons {
  margin-left: auto;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.translate-buttons a {
  text-decoration: none;
  color: #111;
  transition: color 0.3s;
}

.translate-buttons a:hover {
  color: #0056b3;
}

.waterfall-container {
  margin-top: 50px;
  column-count: 2;
  column-gap: 10px;
}

@media (min-width: 768px) {
  .waterfall-container {
    column-count: 4;
  }
}

.waterfall-item {
  break-inside: avoid;
  margin-bottom: 10px;
}

.data-img {
  max-width: 100%;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

.item-price {
  color: #d14;
  font-size: 14px;
}

.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.skeleton-item {
  width: calc(50% - 5px);
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.skeleton-image {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
}

.skeleton-text {
  height: 20px;
  margin: 10px 0;
  background-color: #e0e0e0;
}

.skeleton-price {
  height: 16px;
  width: 60%;
  background-color: #e0e0e0;
  margin-bottom: 10px;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.section-title .line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 10px;
}

.section-title .title {
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* 1:1 宽高比 */
}

.image-container img,
.image-container .skeleton-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
