<template>
  <div>
    <div class="fixed-buttons">
      <button
        @click="navigateToMiniProgram"
        class="refresh-button iconfont icon-fanhuishouye"
      ></button>
      <!-- <button
        @click="goBack"
        class="refresh-button iconfont icon-houtui"
      ></button>
      <button
        @click="toggleCurrency"
        class="refresh-button iconfont icon-huishuai"
      ></button> -->
      <div class="translate-buttons">
        <a
          href="javascript:translate.changeLanguage('chinese_simplified');"
          class="ignore"
          >中文</a
        >
        &nbsp;|&nbsp;
        <a
          href="javascript:translate.changeLanguage('japanese');"
          class="ignore"
          >日本語</a
        >
      </div>
    </div>
    <div v-if="data">
      <div class="carousel" v-if="data.photos.length > 0">
        <div
          class="carousel-container"
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
          <div
            v-for="(photo, index) in data.photos"
            :key="index"
            class="carousel-slide"
          >
            <img :src="photo" :alt="data.name" class="carousel-image" />
          </div>
        </div>
        <button
          v-if="data.photos.length > 1"
          @click="prevSlide"
          class="carousel-control prev"
        >
          ‹
        </button>
        <button
          v-if="data.photos.length > 1"
          @click="nextSlide"
          class="carousel-control next"
        >
          ›
        </button>
      </div>
      <div class="translate title">{{ data.name }}</div>
      <div class="item-info" v-if="data.price">
        <span class="item-price">{{ convertPrice(data.price) }}</span>
      </div>
      <div
        class="translate"
        v-html="data.description"
        style="margin-bottom: 50px"
      ></div>
      <a
        v-if="data?.type != '1'"
        @click="copyProductLink"
        class="product-link"
        href="javascript:void(0);"
        >复制商品链接</a
      >
    </div>
    <div v-else>
      <div class="skeleton-screen">
        <div class="skeleton-image"></div>
        <div class="skeleton-text"></div>
        <div class="skeleton-price"></div>
      </div>
    </div>
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <!-- 悬浮底边栏 -->
    <div v-if="data?.type == '1'" class="bottom-bar">
      <button
        class="bottom-button iconfont icon-fenxiang"
        @click="copyLink(data)"
      ></button>
      <button
        class="bottom-button iconfont"
        :class="
          data && data.is_marked
            ? 'icon-shoucangwancheng1 marked'
            : 'icon-shoucang'
        "
        @click="mark(data)"
      ></button>
      <button class="bottom-button cart-button" style="display: none">
        加入购物车
      </button>
      <button class="bottom-button buy-button" style="display: none">
        直接购买
      </button>
      <button class="bottom-button buy-button" disabled="true">
        复制商品编号联系客服购买
      </button>
    </div>
  </div>
</template>

<script>
import api from "@/api/axios";
import axios from "axios";
/* global wx */
export default {
  props: ["id", "token", "feedItemId"],
  data() {
    return {
      data: null,
      error: null,
      currentIndex: 0,
      isYen: true,
      conversionRate: 0.065, // 假设有人民币的转换率
    };
  },
  created() {
    window.translate.language.setLocal("japanese");
    if (this.token) {
      localStorage.setItem("token", this.token);
    }
    this.fetchData();
    this.fetchConversionRate(); // 如果需要，获取汇率
    this.read();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    document.title = "商品详情";
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    this.translateContent();
  },
  methods: {
    navigateToMiniProgram() {
      if (typeof wx !== "undefined" && wx.miniProgram) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            wx.miniProgram.reLaunch({
              url: "/pages/tabBar/news/news",
            });
          } else {
            console.error("Not in mini program environment");
          }
        });
      } else {
        console.error("wx or wx.miniProgram is not available");
      }
    },
    async fetchData() {
      try {
        const response = await api.get(`/wxservice/article/${this.id}`);

        if (response.data) {
          this.data = response.data;
          if (this.data.photos.length == 0) {
            const imgRegex = /<img.*?src="(.*?)".*?>/g;
            let match;
            const extractedImages = [];
            while (
              (match = imgRegex.exec(response.data.description)) !== null &&
              extractedImages.length < 3
            ) {
              extractedImages.push(match[1]);
            }

            if (extractedImages.length > 0) {
              this.data.photos = extractedImages;
            }

            this.data.description = this.data.description.replace(
              /<img.*?>/g,
              ""
            );
          }
        }
      } catch (error) {
        this.error = "Error fetching data";
      }
    },
    translateContent() {
      this.$nextTick(() => {
        const tranList = document.getElementsByClassName("translate");
        if (tranList.length > 0) {
          window.translate.selectLanguageTag.show = false;
          window.translate.execute(tranList);
        }
      });
    },
    goBack() {
      this.$router.back();
    },
    toggleCurrency() {
      this.isYen = !this.isYen;
    },
    fetchConversionRate() {
      axios
        .get("https://api.exchangerate-api.com/v4/latest/JPY")
        .then((response) => {
          this.conversionRate = response.data.rates.CNY;
        })
        .catch((error) => {
          console.error("获取汇率失败:", error);
        });
    },
    convertPrice(price) {
      return `${price} JPY ≈ ${(price * this.conversionRate).toFixed(2)} RMB`;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.data.photos.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.data.photos.length) %
        this.data.photos.length;
    },
    copyProductLink() {
      const productLink = this.data.link;
      navigator.clipboard
        .writeText(productLink)
        .then(() => {
          alert("原文链接已复制到剪贴板");
        })
        .catch((err) => {
          console.error("复制失败:", err);
        });
    },
    async read() {
      try {
        const payload = {
          feedItemId: this.feedItemId,
          articleIds: [this.id],
          isRead: true,
        };
        await api.post(`/wxservice/article/updateStatus`, payload);
      } catch (error) {
        this.error = "更新数据失败";
      }
    },
    async mark(data) {
      if (!data) return;
      try {
        const payload = {
          site: this.site,
          id: this.id,
          isMarked: !data.is_marked,
        };
        await api.post(`/wxservice/monitor/mark`, payload);
        data.is_marked = !data.is_marked;
      } catch (error) {
        this.error = "更新数据失败";
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.bootcdn.net/ajax/libs/font-awesome/5.15.4/css/all.min.css");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400&display=swap");

.refresh-button {
  background-color: transparent;
  color: #111;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  transition: color 0.3s;
}

.refresh-button:hover {
  color: #0056b3;
}

.fixed-buttons {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px 15px;
  border-radius: 0 0 5px 5px;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.translate-buttons {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.translate-buttons a {
  text-decoration: none;
  color: #111;
  transition: color 0.3s;
  font-family: "Roboto", sans-serif;
}

.translate-buttons a:hover {
  color: #0056b3;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-top: 70px; /* 增加顶部间距 */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  min-width: 100%;
}

.carousel-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 30px;
  cursor: pointer;
  padding: 10px 20px; /* 修改为椭圆形 */
  z-index: 100;
  border-radius: 50px; /* 修改为椭圆形 */
}

.carousel-control.prev {
  left: 10px;
}

.carousel-control.next {
  right: 10px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: "Playfair Display", serif;
  color: #333;
}

.item-info {
  margin-top: 10px;
}

p {
  font-size: 16px;
  text-align: center;
  color: #555;
  font-family: "Roboto", sans-serif;
}

.item-price {
  color: #d14;
  padding: 5px;
  border-radius: 5px;
}

.shipping {
  display: inline-block;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  border: 1px solid #000;
  color: #000;
}

.product-link {
  display: block;
  margin-top: 20px;
  font-size: 18px;
  color: #0056b3;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}

.product-link:hover {
  text-decoration: underline;
}

.skeleton-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.skeleton-image {
  width: 80%;
  height: 200px;
  background: #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.skeleton-text {
  width: 60%;
  height: 20px;
  background: #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.skeleton-price {
  width: 40%;
  height: 20px;
  background: #ddd;
  border-radius: 5px;
}

.bottom-bar {
  position: fixed;
  bottom: -2px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.bottom-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #0056b3;
  font-family: "Roboto", sans-serif;
}

.bottom-button.iconfont {
  font-size: 22px;
}

.cart-button {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.buy-button {
  background-color: #d14;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .carousel-control {
    font-size: 24px;
    padding: 8px 16px; /* 修改为椭圆形 */
  }
}
</style>