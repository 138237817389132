import { createRouter, createWebHistory } from 'vue-router';
import MonitorView from '@/components/MonitorView.vue';
import ArticleView from '@/components/ArticleView.vue';
import ArticleDetail from '@/components/ArticleDetail.vue';
import DetailView from '@/components/MonitorDetail.vue';
import PriceView from '@/components/PriceView.vue';
import MarkedView from '@/components/MarkedView.vue';
import ProfileView from '@/components/ProfileView.vue';

const routes = [
    {
        path: '/monitor/view/:queueName/:jobId/1/:title?',
        name: 'MonitorView1',
        component: MonitorView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/monitor/view/:queueName/:jobId/2/:title?',
        name: 'MonitorView2',
        component: MonitorView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/monitor/view/:queueName/:jobId/3/:title?',
        name: 'MonitorView3',
        component: PriceView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/monitor/detail/:site/:id',
        name: 'DetailView',
        component: DetailView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/article/view/:feedItemId/:title/:type',
        name: 'ArticleView',
        component: ArticleView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/article/detail/:feedItemId/:id',
        name: 'ArticleDetail',
        component: ArticleDetail,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/marked/view',
        name: 'MarkedView',
        component: MarkedView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/profile/view/:site/:sellerId/:title?',
        name: 'ProfileView',
        component: ProfileView,
        props: route => ({ ...route.params, token: route.query.token })
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: {
            template: '<div>Not Found</div>',
            created() {
                console.log('Matched path:', this.$route.path);
                console.log('Route params:', this.$route.params);
                console.log('Route query:', this.$route.query);
            }
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;